import React from "react";
import "./Home.css";

function Home() {
  return (
    <div id="home" className="home-container">
      <div className="home-content">
        <h1 className="main-heading">
          <span className="tech">AI</span> is the{" "}
          <span className="us">BRAIN</span>
        </h1>
        <div className="fine">
          <h1 className="main-heading">
            <span className="tech">IOT</span> is the{" "}
            <span className="us">BODY</span>
          </h1>
          <h2>
            <span className="separator">&</span>
          </h2>
        </div>
        <h2 className="sub-heading">
          <span className="tech">We</span> are the{" "}
          <span className="us">CREATORS</span>
        </h2>
        <p className="description">
          At Ayata Intelligence, we leverage cutting-edge Artificial
          Intelligence (AI) and Internet of Things (IoT) technologies to
          engineer innovative solutions that make a positive impact on the
          world.<br></br> <br></br> Our mission is to seamlessly integrate the
          physical and digital realms, crafting powerful products and Empowering professionals with comprehensive AI, ML, and Data Science courses for a future-ready career.
        </p>
        <div className="home-buttons">
          <a href="#products" className="home-link">
            Discover ViShruti™
          </a>
          <a href="#training" className="home-link">
            Our Courses
          </a>
          <a href="https://chat.whatsapp.com/Dw84oQg9wZV0xIkxZcOjX8" target="_blank" className="home-link">
            Join WhatsApp Group
          </a>
          <a href="https://rzp.io/l/AI4Beginners" target="_blank" className="home-link animate-border">
            Join AI4Beginners
          </a>
        </div>
      </div>
    </div>
  );
}

export default Home;
