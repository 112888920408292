import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./Training.css";
import AI_beg from "./assets/where-is-ai-used.jpg";
import AI_training from "../pages/assets/AI_training.jpg";
import IOT_training from "../pages/assets/IOT_training.jpg";
import IOT_adv from "../pages/assets/IOT_adv.jpg";
import AI_ML from "../pages/assets/AI_ML.jpg";
import business from "../pages/assets/Bussiness_ana.jpg";
import dm from "../pages/assets/Digital_Mark.jpg";
import sanchit from "../pages/assets/founder-sanchit.jpg";
import arpit from "../pages/assets/founder-arpit.jpg";
import mamatha from "../pages/assets/OIP.jpeg";

const courses = [
  {
    title: "Beginner AI Course",
    image: AI_beg,
    description:
      "Are you looking to jump-start your AI journey? Then this workshop is something you should not miss. Join us for an exciting AI Workshop for Beginners. Learn the basics of Python, Pandas, Machine Learning, and more! You don't need any prior knowledge.",
    duration: "1.5 Months",
    price: "Rs 500",
    launchingSoon: false,
    link: "https://rzp.io/l/AI4Beginners",
  },
  {
    title: "Introduction to AI & Machine Learning",
    image: AI_ML,
    description:
      "Embark on your journey into the fascinating world of artificial intelligence and machine learning. Explore the foundational concepts, algorithms, and applications that are revolutionizing industries and shaping the future.",
    duration: "2 Months",
    price: "Rs 800",
    launchingSoon: true,
  },
  {
    title: "Advanced AI & Machine Learning",
    image: AI_training,
    description:
      "Take your AI and machine learning skills to the next level with advanced techniques and real-world applications. Dive deep into deep learning, reinforcement learning, and more, and unleash the full potential of intelligent systems.",
    duration: "2.5 Months",
    price: "Rs 1500",
    launchingSoon: true,
  },
  {
    title: "Fundamentals of IoT",
    image: IOT_training,
    description:
      "Discover the fundamentals of the Internet of Things and learn how connected devices are transforming our world. From sensors to cloud computing, gain the knowledge and skills to build innovative IoT solutions.",
    duration: "2 Months",
    price: "Rs 800",
    launchingSoon: true,
  },
  {
    title: "Advanced IoT Solutions",
    image: IOT_adv,
    description:
      "Master the design and implementation of advanced IoT solutions that drive innovation and efficiency. Explore topics such as edge computing, IoT security, and data analytics, and lead the way in the IoT revolution.",
    duration: "3 Months",
    price: "Rs 1200",
    launchingSoon: true,
  },
  {
    title: "Business Intelligence & Analytics",
    image: business,
    description:
      "Gain the insights and skills needed to drive strategic decisions and business growth through data-driven analysis. From predictive analytics to data visualization, master the tools and techniques used by top business analysts to unlock the full potential of data.",
    duration: "2.5 Months",
    price: "Rs 1200",
    launchingSoon: true,
  },
  {
    title: "Digital Marketing Mastery",
    image: dm,
    description:
      "Become a digital marketing expert and elevate your online presence with cutting-edge strategies and techniques. From SEO to social media marketing, learn how to reach your audience effectively, optimize campaigns, and drive business success in the digital age.",
    duration: "2 Months",
    price: "Rs 1000",
    launchingSoon: true,
  },
];

const instructors = [
  {
    name: "Sanchit Aggarwal",
    course: "Artificial Intelligence",
    image: sanchit,
    linkedin: "https://www.linkedin.com/in/sanchit-aggarwal/",
  },
  // {
  //   name: "Arpit Jain",
  //   course: "Internet of Things",
  //   image: arpit,
  //   linkedin: "https://www.linkedin.com/in/jarpit91/",
  // },
  {
    name: "Dr. Mamatha Raghu",
    course: "Machine Learning",
    image: mamatha,
    linkedin: "https://www.linkedin.com/in/mamatha-alugubelly-9b36b0124/?originalSubdomain=in",
  },
];

function Training() {
  return (
    <div id="training" className="training-page">
      <h1 className="training-heading">
        Enhance Your Skills with Our Expert Training Courses
      </h1>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        slidesPerView={2}
        spaceBetween={20}
        loop={true}
        pagination={{ clickable: true }}
        navigation={true}
        breakpoints={{
          350: {
            slidesPerView: 1,
          },
          640: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 2,
          },
        }}
        autoplay={{ delay: 4000, disableOnInteraction: false }}
        className="mySwiper"
      >
        {courses.map((course, index) => (
          <SwiperSlide key={index}>
            <div className="course-card">
              {/* {course.launchingSoon && (
                <span className="launching-soon-tag">Launching Soon!</span>
              )} */}
              {/* {!course.available && (
                <span className="not-available-tag">Not Available</span>
              )} */}
              <img
                src={course.image}
                alt={course.title}
                className="course-image"
              />
              <div className="course-details">
                <h2 className="course-title">{course.title}</h2>
                <p className="course-description">{course.description}</p>
                {/* {course.duration && (
                  <p className="course-duration">
                    <strong>Duration:</strong> {course.duration}
                  </p>
                )} */}
                {/* {course.price && (
                  <p className="course-price">
                    <strong>Price:</strong> {course.price}
                  </p>
                )} */}
                {/* {course.available ? (
                  <button className="enroll-button">Enroll Now</button>
                ) : (
                  <p className="course-not-available">Course Not Available</p>
                )} */}
                {/* {course.launchingSoon && (
                  <span className="launching-soon-tag">Launching Soon!</span>
                )} */}
                 {course.launchingSoon ? (
                  <span className="launching-soon-tag">Launching Soon!</span>
                ) : (
                  <a
                    href={course.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="enroll-button"
                  >
                    Enroll Now
                  </a>
                )}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="whatsapp-group">
        <p>
          Join our WhatsApp group for latest updates and discussions:{" "}
          <a href="https://chat.whatsapp.com/Dw84oQg9wZV0xIkxZcOjX8" target="_blank">
            Join Now!
          </a>
        </p>
      </div>
      <h2 className="instructors-heading">Meet Our Expert Instructors</h2>
      <div className="instructors">
        {instructors.map((instructor, index) => (
          <div className="instructor-card" key={index}>
            <img
              src={instructor.image}
              alt={instructor.name}
              className="instructor-image"
            />
            <h3 className="instructor-name">{instructor.name}</h3>
            <p className="instructor-course">
              <strong>
                <span className="ccl">Course:</span>{" "}
              </strong>
              {instructor.course}
            </p>
            <a
              href={instructor.linkedin}
              target="_blank"
              rel="noopener noreferrer"
              className="linkedin-link"
            >
              Connect on LinkedIn
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Training;
