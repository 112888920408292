import React from 'react';
import './PrivacyPolicy.css';

function PrivacyPolicy() {
  return (
    <div className="policy-container">
      <h1>Privacy Policy</h1>
      <p>
        We respect and are committed to protecting your privacy. Publishing, selling, or renting any personal data or information to any third party, without your consent, is against our ethics. The privacy practices of this statement apply to our services available under the domain and subdomains of the Site. By visiting this Site, you agree to be bound by the terms and conditions of this privacy policy. If you do not agree, please do not use or access our site.
      </p>
      <p>
        This privacy policy does not apply to sites maintained by other companies or organizations to which we link and we are not responsible for any personal information you submit to third parties via our website. Please ensure that you read the privacy policy of such other companies or organizations before submitting your details.
      </p>
      <p>
        This privacy policy describes the information, as part of the normal operation of our services, we collect from you and what may happen to that information. This policy is formulated and displayed to inform you about our information collection/retention policies and practices so that you can make an informed decision regarding the sharing of your personal information with us. By accepting the privacy policy and the user agreement or by using the site in general, you give your consent to our use and disclosure of your personal information in accordance with this privacy policy. This Privacy Policy is incorporated into and subject to the terms of the User Agreement. This privacy policy is effective upon acceptance of access by you to the site.
      </p>
      <h2>Privacy Guarantee</h2>
      <p>
        We agree that we will not sell or rent your personal information to third parties for their marketing purposes without your explicit consent. From time to time, we may reveal general statistical information about our Site and visitors, such as the number of visitors, number and type of goods and services purchased, etc. Only those of our employees who need access to your information in order to perform their duties are allowed such access. Any employee who violates our privacy and/or security policies is subjected to disciplinary action, including possible termination and civil and/or criminal prosecution.
      </p>
      <h2>Information We Collect</h2>
      <p>
        The Personal Information is used for two general purposes: to process your order, and to provide you with the best possible services. Unless otherwise stated explicitly, this policy applies to personal information as disclosed on any of the media.
      </p>
      <p>
        In furtherance of the confidentiality with which we treat Personal Information, we have put in place appropriate physical, electronic, and managerial procedures to safeguard and secure the information we collect online. We use data collection devices such as "cookies" on certain pages of the Site to help and analyze our web page flow, measure promotional effectiveness, and promote trust and safety. "Cookies" are small files placed on your hard drive that assist us in providing our services. We offer certain features that are only available through the use of a "cookie". Cookies can also help us provide information that is targeted to your interests. Most cookies are "session cookies," which means that they are automatically deleted from your hard drive at the end of a session. You are always free to decline our cookies if your browser permits. Additionally, you may encounter "cookies" or other similar devices on certain pages of the Site that are placed by third parties. For example, if you view a web page created by a user, there may be a "cookie" placed within that web page. We do not control the use of cookies by third parties.
      </p>
      <p>
        You acknowledge that you are disclosing Personal Information voluntarily. We identify and use your IP address to help diagnose problems with our server and to administer our Website. Your IP address is also used to help identify you and to gather broad demographic information.
      </p>
    </div>
  );
}

export default PrivacyPolicy;
