import React from 'react';
import './ReturnRefundPolicy.css';

function ReturnRefundPolicy() {
  return (
    <div className="policy-container">
      <h1>Return and Refund Policy</h1>
      
      <h2>Refund Policy for Products</h2>
      <p>Once we receive the product successfully, Ayata Intelligence will initiate the refund process promptly. The refund will be made to your source account or chosen method of refund within 30 working days.</p>
      
      <h2>Refund and Cancellation for Workshops</h2>
      <p>Due to the nature of our workshop services, we do not entertain refunds or cancellations once payment has been made. If you are unable to attend a workshop for any reason, you have the option to attend the next available batch. We conduct workshops every month, providing you with an opportunity to participate at a later date that suits your schedule.</p>
      
      <p>We appreciate your understanding in adhering to our refund and cancellation policy. Should you have any further questions or require assistance, please don't hesitate to reach out to our customer support team.</p>
    </div>
  );
}

export default ReturnRefundPolicy;
