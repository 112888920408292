// App.js
import React from "react";
import { Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import NavBar from "./pages/Navbar";
import Services from "./pages/Services";
import Products from "./pages/Products";
import Training from "./pages/Training";
import Achievements from "./pages/Achievements";
// import Team from "./pages/Team";
import Career from "./pages/Career";
import Contact from "./pages/Contact";
import Client from "./pages/Client";
import Footer from "./pages/Footer";
import Blogs from "./pages/Blogs";
// import PrivacyPolicy from "./pages/privacyPolicy";
// import ReturnRefundPolicy from "./pages/ReturnRefundPolicy";
// import TermsConditions from "./pages/TermsConditions";

function App() {
  return (
    <div className="App">
      <NavBar />
      <div className="pages">
        <Home />
        {/* <marquee width="100%" direction="left" height="30px">
        Ayata Intelligence.
      </marquee> */}
        <Training />
        <Services />
        <Products />
        <Achievements />
        <Blogs />
        <Client />
        <Career />
        {/* <Contact /> */}
      </div>
      <Footer />
    </div>
  );
}

export default App;
