import React from 'react';
import './Blogs.css';
import blog1 from './assets/blog 1.jpg';

const blogs = [
  {
    title: "How to Choose the Right Career",
    image: blog1,
    link: "https://mypivot.work/blogs/how-to-choose-the-right-career-with-sanchit-aggarwal/",
    author: "Sanchit Aggarwal"
  },
  // Add more blogs here
];

function Blogs() {
  return (
    <div id='blogs' className="blogs-container">
      <div className='blog-heading'>
        <h1>Blogs</h1>
        <h2>Explore our latest articles and insights</h2>
      </div>
      <div className='blogs-card'>
        {blogs.map((blog, index) => (
          <div key={index} className="blog-card" onClick={() => window.location.href = blog.link}>
            <div className="blog-image-container">
              <img src={blog.image} alt={blog.title} className="blog-image" />
              <div className="blog-overlay">
                <h3 className="blog-title">{blog.title}</h3>
                <p className="blog-author">- by {blog.author}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Blogs;
