import React from 'react';
import '../pages/Carrer.css';


function Career() {
  const handleButtonClick = () => {
    window.open('https://your-google-form-url', '_blank'); // Open in a new tab
  };

  return (
    <div id='careers' className="career-container">
      <h2 className='career-container-heading'>Want To Join Us?</h2>
      <div className="career-box">
        <div className="about-us-section">
          <h2>About Us</h2>
          <p>
          At Ayata Intelligence, we harness the power of Artificial Intelligence (AI) and the Internet of Things (IoT) to develop innovative products and services that make a meaningful impact. Our mission is to seamlessly integrate the physical and digital worlds, creating solutions that enhance everyday life in a natural and intuitive way.
          </p>
        </div>
        <div className="career-heading">
          <h1>Join Our Team</h1>
          <p>Join us to explore exciting career paths!</p>
        </div>
        <a href="https://forms.gle/xutDhbQLB62EBEdY8" className="apply-button" target="_blank" rel="noopener noreferrer">Apply</a>
      </div>
      <img className="wave-image" src="/wave.svg" alt="Wave" />
    </div>
  );
}

export default Career;
