import React, { useState } from 'react';
import './Contact.css';

function Contact() {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const handleSendClick = () => {
    const mailtoLink = `mailto:naval.2@iitj.ac.in`;
    window.location.href = mailtoLink;
  };

  return (
    <div id='contacts' className="contact-container">
      <h1 className='contact-container-heading'>Contact Us</h1>
      <div className="blur-background"></div> {/* Transparent background overlay */}
      <div className="contact-content">
        <div className="contact-info">
          <h2>Contact Information</h2>
          <div className="input-group">
            <label htmlFor="fullName">Full Name</label>
            <input
              type="text"
              id="fullName"
              placeholder="Your full name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
          </div>
          <div className="input-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              placeholder="Your email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="input-group">
            <label htmlFor="phone">Phone</label>
            <input
              type="tel"
              id="phone"
              placeholder="Your phone number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
        </div>
        <div className="contact-form">
          <h2>Send us a Message</h2>
          <textarea
            className='textarea-group'
            placeholder="Your message..."
            rows="6"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
          <button onClick={handleSendClick}>Send</button>
        </div>
      </div>
    </div>
  );
}

export default Contact;
