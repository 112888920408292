import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { GrYoutube } from "react-icons/gr";
import { FaLinkedinIn, FaGithub } from "react-icons/fa";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section about-us">
          <h3>About Us</h3>
          <p>
            Ayata Intelligence strives to create products and services that
            create a positive impact by leveraging the benefits of Artificial
            Intelligence, computer vision, and Machine learning.
          </p>
        </div>
        <div className="footer-section support">
          <h3>Support</h3>
          <ul>
            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
            <li><Link to="/return-refund-policy">Return and Refund Policy</Link></li>
            <li><Link to="/terms-conditions">Terms and Conditions</Link></li>
            <li><Link to="/shipping-conditions">Shipping Policy</Link></li>
          </ul>
        </div>
        <div className="footer-section contact-us">
          <h3>Contact Us</h3>
          <p>Ayata Intelligence Pvt. Ltd.</p>
          <p><b>Email:</b> Info@ayataintelligence.com</p>
          <p><b>Phone:</b> +91 9599914113</p>
          <p><b>Office Address:</b> F198, First floor, Gyan Khand 2, Indirapuram, Ghaziabad 201014</p>
        </div>
        <div className="footer-section social-media">
          <h3>Follow Us</h3>
          <a
            href="https://www.youtube.com/@ayataintelligence"
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
          >
            <GrYoutube />
          </a>
          <a
            href="https://www.linkedin.com/company/ayataintelligence/?originalSubdomain=in"
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
          >
            <FaLinkedinIn />
          </a>
          {/* <a
            href="https://www.github.com"
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
          >
            <FaGithub />
          </a> */}
        </div>
      </div>
      <hr></hr>
      <div className="trade-mark">
        {/* <img src=""></img> */}
      <p>Ayata Intelligence Pvt. Ltd.</p>
      </div>
    </footer>
  );
}

export default Footer;
