import React from 'react';
import './ShippingPolicy.css';

function ShippingPolicy() {
  return (
    <div className="policy-container">
      <h1>Shipping Policy</h1>

      <h2>Ayata Intelligence Online Courses Shipping Policy</h2>
      <p>Thank you for choosing Ayata Intelligence for your online learning journey. Please review our shipping policy for digital course materials:</p>

      <h3>Course Access and Delivery:</h3>
      <ul>
        <li>Upon successful purchase, you will receive an email confirmation with details on how to access your online course.</li>
        <li>Digital course materials, including videos, PDFs, and other resources, will be available for download or streaming directly from our learning platform (LMS).</li>
        <li>The delivery of the course will be done on the scheduled date of the course. If the course is pre-recorded, you will be given access via our LMS platform.</li>
      </ul>

      <h3>Delivery Time:</h3>
      <p>Course access details and digital materials are typically delivered instantly. In rare cases, delivery may take up to 24 hours due to technical issues or payment processing delays. If you do not receive access within 24 hours, please contact our support team at info@ayataintelligence.com.</p>

      <h3>Technical Requirements:</h3>
      <ul>
        <li>Ensure you have a stable internet connection to access and download course materials.</li>
        <li>Verify that your device meets the minimum system requirements specified in the course details to ensure optimal learning experience.</li>
      </ul>

      <h3>Course Schedule and Cancellations:</h3>
      <ul>
        <li>In case of cancellation of a live course or workshop, we will inform you of the new batch schedule.</li>
        <li>Our courses are conducted regularly each month. If a scheduled session is canceled, you will be notified of the next available session.</li>
      </ul>

      <h3>Refunds and Cancellations:</h3>
      <p>For information on refunds and cancellations, please refer to our <a href="/return-refund-policy">Refund Policy here</a>.</p>

      <h3>Support:</h3>
      <p>For any issues related to course access or digital material delivery, contact our support team at <a href="mailto:info@ayataintelligence.com">info@ayataintelligence.com</a> or call us at +91 9599914113.</p>

      <p>We are committed to providing you with a seamless and enriching learning experience. Thank you for choosing Ayata Intelligence!</p>
    </div>
  );
}

export default ShippingPolicy;
