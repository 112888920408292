import React, { useState } from 'react';
import '../pages/Client.css';
import clientImage1 from '../pages/assets/Cyrrup-Logo.png';
import clientImage2 from '../pages/assets/upgrad.svg';
// import { SectionWrapper } from './motion';

function Client() {
  const clients = [
    {
      name: 'Cyrrup Solutions Pvt Ltd.',
      description: 'We supplied them with their first AI engine for their solution, specifically designed for fuel use detection.',
      moreContent: `
        Project Overview
        We are proud to have partnered with Cyrrup Solutions Pvt Ltd., a visionary company dedicated to providing innovative technology solutions. Our collaboration centered on the development of their core technology: an advanced AI engine specifically designed for fuel use detection and theft prevention.

        Our Contribution
        Cyrrup Solutions Pvt Ltd. entrusted us with the complete design, architecture, and deployment of their first AI engine. This engine is at the heart of their solution, employing sophisticated machine learning algorithms to accurately monitor fuel consumption and detect theft.

        Key Features
        Precision Monitoring: Our AI engine delivers high-accuracy data on fuel consumption, enabling optimal fuel management and cost savings.
        Theft Detection: Advanced algorithms detect anomalies and potential theft, providing timely alerts and enhancing security.
        Real-Time Analysis: Immediate data analysis and reporting facilitate prompt decision-making and operational adjustments.
        Comprehensive Technology Transfer: We provided extensive training and support, ensuring a smooth transition and empowering Cyrrup Solutions' team to manage the technology independently.

        Impact
        Since integrating our AI engine, Cyrrup Solutions Pvt Ltd. has experienced substantial improvements in fuel management efficiency and security. The enhanced detection capabilities have not only reduced operational costs but also significantly mitigated fuel theft, reinforcing their competitive position in the market.

        We look forward to continuing our partnership and supporting Cyrrup Solutions Pvt Ltd. in their journey towards technological excellence.
      `,
      image: clientImage1,
    },
    {
      name: 'UpGrad',
      description: 'Provided them complete Lecture series on Machine Learning Deployment.',
      moreContent: `
        Project Overview
        We are delighted to partner with UpGrad, a leading online higher education platform that empowers learners with industry-relevant skills. Our collaboration aimed at enhancing the learning experience for UpGrad's clients and students through comprehensive training and support in machine learning.

        Our Contribution
        UpGrad entrusted us with several key responsibilities to elevate their educational offerings:
        
        Onsite Training: We provided expert-led onsite training sessions for UpGrad clients, ensuring they receive practical, hands-on experience in machine learning applications.
        Machine Learning Deployment Series: We were integral in creating the entire machine learning deployment series, a structured and detailed curriculum designed to equip learners with the necessary skills to deploy machine learning models in real-world scenarios.
        Master Thesis Supervision: Our experts supervised master thesis projects, guiding learners through their research and ensuring high-quality, academically rigorous work.
        Doubt Resolution Sessions: We conducted regular doubt resolution sessions, helping UpGrad learners overcome challenges and gain a deeper understanding of complex machine learning concepts.

        Impact
        Our collaboration with UpGrad has significantly enriched their educational programs. The onsite training and deployment series have provided learners with critical skills and practical knowledge. Our ongoing support through master thesis supervision and doubt resolution sessions has helped learners achieve their academic and professional goals, contributing to UpGrad’s reputation as a premier education provider.

        We are excited to continue our partnership with UpGrad and support their mission to transform education through cutting-edge technology and expert guidance.
      `,
      image: clientImage2,
    },
  ];

  const [expandedIndex, setExpandedIndex] = useState(Array(clients.length).fill(false));

  const handleMoreClick = (index) => {
    const newExpandedIndex = [...expandedIndex];
    newExpandedIndex[index] = !newExpandedIndex[index];
    setExpandedIndex(newExpandedIndex);
  };

  const renderMoreContent = (content) => {
    const sections = content.split('\n').filter(line => line.trim() !== '');
    return sections.map((section, i) => {
      const trimmedSection = section.trim();
      const boldWords = ["Project Overview", "Our Contribution", "Key Features", "Impact"];
      const listItems = ["Precision Monitoring:", "Theft Detection:", "Real-Time Analysis:", "Comprehensive Technology Transfer:", "Onsite Training:", "Machine Learning Deployment Series:", "Master Thesis Supervision:", "Doubt Resolution Sessions:"];
      
      if (boldWords.includes(trimmedSection)) {
        return <h3 key={i}>{trimmedSection}</h3>;
      } else {
        return (
          <p key={i}>
            {listItems.some(item => trimmedSection.startsWith(item)) ? (
              <><strong>{trimmedSection.split(': ')[0]}:</strong> {trimmedSection.split(': ')[1]}</>
            ) : trimmedSection}
          </p>
        );
      }
    });
  };

  return (
    <div id='clients' className="client-container">
      <h1>Our Clients</h1>
      <div className="client-card-container">
        {clients.map((client, index) => (
          <div key={index} className={`client-card ${expandedIndex[index] ? 'expanded' : ''}`}>
            <div className="client-image-container">
              <img src={client.image} alt={client.name} className="client-image" />
            </div>
            <div className="client-details">
              <h2>{client.name}</h2>
              <p>{client.description}</p>
              <button className="more-button" onClick={() => handleMoreClick(index)}>
                {expandedIndex[index] ? 'Less' : 'Read more!'}
              </button>
              {expandedIndex[index] && (
                <div className="more-content">
                  {renderMoreContent(client.moreContent)}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Client;
